import { Action } from '../actions/actions'
const INITIAL_STATE = {
  Messages: [
    {
      text:
        'Hi there 👋, how can I help?\n\n' +
        "I'm AI bot in beta stage trained on TP documentation. I can provide supplementary consultations on TP; also, sometimes I'm wrong.\n\n" +
        "So, for anything important, please contact our live tech support engineers. They'll be prompt to assist.",
      role: 'bot',
    },
  ],
}
function ChatBotReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Action.ChatBotReset:
      return {
        ...state,
        Messages: [
          {
            text:
              'Hi there 👋, how can I help?\n\n' +
              "I'm AI bot in beta stage trained on TP documentation. I can provide supplementary consultations on TP; also, sometimes I'm wrong.\n\n" +
              "So, for anything important, please contact our live tech support engineers. They'll be prompt to assist.",
            role: 'bot',
          },
        ],
      }
    case Action.ChatBotSetMessage:
      return {
        ...state,
        Messages: [...state.Messages, action.data],
      }
    default:
      return state
  }
}
export function AddMessage(data: any) {
  return { type: Action.ChatBotSetMessage, data }
}
export function ResetMessage() {
  return { type: Action.ChatBotReset }
}
export default ChatBotReducer
